// Fonts.
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Racing+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Racing+Sans+One&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

$font-primary: 'Cabin';
$font-secondary: 'Inter';
$font-roboto: 'Roboto';

// Main Colors.

$green: #3b723b;
$lightGreen: #7E997E;
$orange: #d98829;
$white: #ffffff;
$grey: #646b6e;
$black: #000000;
$textGrey: #6C6464;
$borderGrey: #E9E9E9;
$bgSubMenu: #F7ECE4;

.fs100 {
	font-size: 1rem;
}
.fs200 {
	font-size: 1.1rem;
}
.fs300 {
	font-size: 1.2rem;
}
.fs400 {
	font-size: 1.3rem;
}
.fs500 {
	font-size: 2rem;
}
.fs600 {
	font-size: 2.1rem;
}
.fs700 {
	font-size: 2.3rem;
}

