.our-story-wrapper {
	margin-top: 4rem;
	> p {
		color: #646B6E;
		font-weight: 400;
		@include mq(mobile, desktop) {
			font-size: 1.1rem;
		}
		@include mq($from: desktop) {
			font-size: 1.3rem;
		}
	}
}

.top-distributions-wrapper {
	margin-top: 4rem;
	> p {
		color: #646B6E;
		font-weight: 400;
		@include mq($from: desktop) {
			font-size: 1.1rem;
		}
	}
}

.companies-images {
	margin-top: 2rem;
	> img {
		display: block;
		width: 100%;
		margin: 0 auto;
		border-radius: 5px;

		@include mq(mobile, tablet) {
			margin-bottom: 1.5rem;
		}
	}

	@include mq(mobile, tablet) {
		display: block;
	}

	@include mq($from: tablet) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}
}
.our-story-images {
	margin-top: 2rem;
	> img {
		display: block;
		width: 100%;
		margin: 0 auto;
		border-radius: 5px;

		@include mq(mobile, tablet) {
			margin-bottom: 1.5rem;
		}
	}

	@include mq(mobile, tablet) {
		display: block;
	}

	@include mq($from: tablet) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}
}

.why-selecta-wrapper {
	display: block;
	margin-top: 4rem;
	@include mq($from: tablet) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2rem;
		margin-top: 7rem;
		align-items: center;
	}
}

.why-selecta-block {
	> h2 {
		@include mq($from: tablet) {
			text-align: center;
		}
	}
	> p {
		color: #646B6E;
		font-weight: 400;
		@include mq(mobile, desktop) {
			font-size: 1.3rem;
		}

		@include mq($from: tablet) {
			font-size: 2.2rem;
			text-align: center;
		}
	}
}

.video-block {
	margin-top: 3rem;
}

.mision-vision-wrapper {
	margin-top: 4rem;
}

.valores-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
	margin-top: 2rem;
	@include mq(mobile, tablet) {
		display: block;
	}

	@include mq(tablet, desktop) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	@include mq($from: desktop) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 2rem;
	}

	> li {
		position: relative;
		padding-left: 25px;
		@include mq(mobile, tablet) {
			margin-bottom: 2rem;
		}
		&::before {
			content: '';
			width: 10px;
			height: 10px;
			position: absolute;
			background-image: url('../images/bullet.png');
			background-size: cover;
			background-position: center;
			left: 0;
			top: 14px;
			transform: translateY(-50%);
			@include mq($from: tablet) {
				width: 15px;
				height: 15px;
			}
		}

		> p:nth-of-type(1) {
			font-weight: 600;
			color: #333333;
			margin-bottom: 3px;
			@include mq(mobile, desktop) {
				font-size: 19px;
			}
			@include mq($from: desktop) {
				font-size: 24px;
			}
		}
		> p:nth-of-type(2) {
			color: #666666;
			font-weight: 400;
			@include mq(mobile, desktop) {
				font-size: 1rem;
			}
			@include mq($from: desktop) {
				font-size: 1.1rem;
			}
		}
	}
}

.img-mision {
	display: block;
	width: 70%;
	margin: 0 auto;
	@include mq($from: tablet) {
		width: 55%;
	}
}

.img-valores {
	display: block;
	width: 50%;
	margin: 0 auto;
	@include mq(mobile, tablet) {
		width: 72%;
		margin: 2rem auto 1rem;
	}
	@include mq($from: tablet) {
		width: 30%;
	}
}

.top-companies-wrapper {
	@include mq(mobile, desktop) {
		margin-top: 5rem;
	}
	@include mq($from: desktop) {
		margin-top: 5rem;
	}
}

.quality-text-wrapper {
	@include mq(mobile, tablet) {
		display: block;
		margin: 1rem 0;
		width: 100%;
	}

	@include mq(tablet, desktop) {
		width: 100%;
		display: grid;
		margin: 1.2rem auto;
		gap: 1.5rem;
		grid-template-columns: 1fr 1fr;
	}

	@include mq($from: desktop) {
		width: 80%;
		margin: 1rem auto;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 2rem;
	}

}

.quality-block {
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	@include mq(mobile, tablet) {
		margin-bottom: 1.2rem;
	}
	div {
		background-color: $orange;
		text-align: center;
		color: white;
		font-size: 1.3rem;
		padding: 10px;
		border-radius: 8px;
		width: 50px;
		margin-bottom: 15px;
	}

	p {
		margin-bottom: 0;
		color: $grey;
		@include mq(mobile, tablet) {
			font-size: 1rem;
		}

		@include mq($from: tablet) {
			font-size: 1.1rem;
		}
	}
}

.distribuitors-wrapper {
	margin-top: 3rem;
	@include mq(mobile, tablet) {
		display: block;
	}
	@include mq(tablet, desktop) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2rem;
	}
	@include mq($from: desktop) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 2rem;
	}
}

.distribuitor-block {
	padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 3px;

	@include mq(mobile, tablet) {
		margin-bottom: 2rem;
	}

	h3 {
		color: $orange;
		text-align: center;
		width: 97%;
		margin: 0 auto 1.3rem;
		padding-bottom: 10px;
		border-bottom: 1px solid #ddd;
		@include mq(mobile, tablet) {
			font-size: 1.2rem;
		}
		@include mq($from: tablet) {
			font-size: 1.5rem;
		}
	}
}

.distribuitor-information-block {
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
	align-items: center;
	svg {
			font-size: 1.3rem;
			path {
			fill: $green;
		}
	}
	> a {
		text-decoration: none;
		&:hover, &:active, &:focus {
			text-decoration: underline;
		}
	}

	p {
		color: $grey;
		font-weight: 400;
		margin-bottom: 0;
		@include mq($from: desktop) {
			font-size: 1.1rem;
		}
	}
}


.tab-title-cultivo::after {
	content: "Cultivo";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}

.tab-title-cultivo-en::after {
	content: "Cultivation";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}

.tab-title-cosecha-en::after {
	content: "Harvest";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}
.tab-title-cosecha::after {
	content: "Cosecha";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}

.tab-title-sapecado-en::after {
	content: "Sapecado";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}
.tab-title-sapecado::after {
	content: "Sapecado";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}

.tab-title-secado-en::after {
	content: "Drying process";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}
.tab-title-secado::after {
	content: "Secado";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}

.tab-title-estacionado-en::after {
	content: "Stationing";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}
.tab-title-estacionado::after {
	content: "Estacionado";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}

.tab-title-molienda-en::after {
	content: "Milling";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}
.tab-title-molienda::after {
	content: "Molienda";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}

.tab-title-envasado-en::after {
	content: "Packaging";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}
.tab-title-envasado::after {
	content: "Envasado";
	font-size: 1.4rem;
	font-weight: 600;
	color: $grey;
	text-align: center;
	display: block;
}

.de-la-yerba-mate-wrapper {
	h2 {
		text-align: center;
		margin: 2rem 0 5rem;
		@include mq($from: desktop) {
			font-size: 3.75rem;
		}
	}
}
.formas-yerba-mate-wrapper {
	margin-top: 8rem;
}

.h2-title-double {
	@include mq(mobile, tablet) {
		font-size: 2rem;
	}
	@include mq($from: tablet) {
		font-size: 3.75rem;
	}
}

.metodos-yerba-mate-wrapper {
	img {
		display: block;
		width: 70%;
		margin: 0 auto;
	}
	@include mq(mobile, tablet) {
		display: block;
	}
	@include mq($from: tablet) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
}

.logo-section {
	width: 280px;
	margin: 0 auto 1rem;
	display: block;
}

.image-selecta-group-bottom {
	display: flex;
	justify-content: flex-end;
	@include mq(mobile, tablet) {
		display: block;
	}
	> img {
		@include mq(mobile, tablet) {
			display: block;
			width: 100%;
		}
	}
}

.bg-empresas-text {
    background-image: url('../images/empresa_1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    height: 650px !important;
    @include mq(mobile, tablet) {
        padding: 0 25px;
        background-attachment: initial;
        background-position: center;
    }

    @include mq(tablet, desktop) {
        padding: 0 25px;
        background-attachment: initial;
        background-position: center;
    }
}

.bg-mirador-text {
    background-image: url('../images/el_mirador.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 21rem 0;

    @include mq(mobile, tablet) {
        background-position: center;
        padding: 0 25px;
        background-attachment: initial;
    }

    @include mq(tablet, desktop) {
        background-position: center;
        padding: 0 25px;
        background-attachment: initial;
    }
}

.sgs-text {
    @include mq(mobile, tablet) {
        font-size: 20px;
        width: 90%;
        margin: 0 auto;
    }
    @include mq(tablet, desktop) {
        font-size: 20px;
        width: 60%;
        margin: 0 auto;
    }
    @include mq(desktop, wide) {
        font-size: 20px;
        width: 40%;
        margin: 0 auto;
    }
    @include mq(wide) {
        font-size: 20px;
        width: 40%;
        margin: 0 auto;
    }
}

.farmer-logo {
    width: 500px;
    @include mq(mobile, tablet) {
        width: 330px;
    }
    @include mq(tablet, desktop) {
        width: 330px;
    }
}

.cosdam-logo {
    width: 300px;
    @include mq(mobile, tablet) {
        width: 200px;
    }
    @include mq(tablet, desktop) {
        width: 250px;
    }
}

.height-auto {
    height: auto !important;
}

.mirador-flex-content-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem 1rem;
    align-items: center;
    width: 72rem !important;

    @include mq(mobile, tablet) {
        width: 93% !important;
        align-items: flex-start;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    @include mq(tablet, desktop) {
        width: 93% !important;
        align-items: flex-start;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.mirador-content {
    text-align: center;
    flex: 0 0 360px;
    border-right: 1px solid #fff;
    @include mq(mobile, desktop) {
        border-right: none;
    }
    &:nth-last-of-type(3) {
        border-right: none;
    }

    &:last-of-type {
        border-right: none;
    }

    > h2 {
        font-size: 3rem !important;
        @include mq(mobile, tablet) {
            font-size: 2rem !important;
        }
        @include mq(tablet, desktop) {
            font-size: 2rem !important;
        }
    }
    > p {
        font-size: 15px;
    }
}

.column-policy {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 2rem;

    > p:first-child:before {
        content: "";
        font-size: 20px;
        color: #d98829;
        margin-right: 10px;
        line-height: 1;
        position: absolute;
        left: 33px;
        top: 10px;
        @include mq(mobile, tablet) {
            left: 12px;
        }
        @include mq(tablet, desktop) {
            left: 10px;
        }
        @include mq(desktop, wide) {
            left: 8px;
        }
        @include mq(wide) {
            left: 33px;
        }
    }

    > p:first-child {
        position: relative;
        font-size: 40px;
        font-weight: 700;
        text-align: right;
        margin: 0;
        line-height: 1;
    }
    > div > h3 {
        font-weight: 700;
        font-size: 1.3rem;
    }
    > div > p {
        font-size: 16px;
        font-weight: 400;
    }
}

.selecta-empresas-hoja-right {
    width: 500px !important;
    right: -201px !important;
    top: -70px !important;
}

.selecta-empresas-hoja-bottom {
    width: 500px !important;
    left: -198px !important;
    bottom: -42px !important;
    top: inherit !important;
}

.comercio-exterior-bg {
    position: relative;
}

.img-global-map {
    width: 60%;
    margin: 0 auto 3rem;
    @include mq(mobile, desktop) {
        width: 100%;
    }
}

.inner-orange-bg-content {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
    @include mq(mobile, desktop) {
        padding-bottom: 2rem !important;
    }
}

.bronweih-logo {
    @include mq(mobile, tablet) {
        width: 300px;
    }
}

.sgs-logo {
    @include mq(mobile, tablet) {
        width: 100%;
    }
}
