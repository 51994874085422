.mainHeader {
	position: relative;
    background-color: rgb(59, 114, 59);

    @include mq(mobile, tablet) {
        position: fixed;
        width: 100%;
        z-index: 10;
    }
    @include mq(tablet, desktop) {
        position: fixed;
        width: 100%;
        z-index: 10;
    }
}

main {
    width: 100%;
    @include mq(mobile, desktop) {
        padding-top: 95px;
    }
}

.headerContainer {
	width: 90%;
	margin: 0 auto;
	//padding: 10px 0;
	@include mq(mobile, tablet) {
		width: 90%;
	}
	@include mq(desktop, wide) {
		width: 95%;
	}
	@include mq(wide) {
		width: 85%;
	}
}

.button-menu-mobile {
	background-color: transparent;
	border: 0;
	&:selected, &:hover, &:focus {
		background-color: transparent;
	}
}

.menuContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	gap: 1rem;
	min-height: 5rem;
    position: relative;
    @include mq(mobile, tablet) {
        justify-content: space-between;
    }
    @include mq(tablet, desktop) {
        justify-content: space-between;
    }
}

.logoLink {
	@include mq(mobile, tablet) {
		flex: 0 1 35%;
        height: 95px;
	}

	@include mq(tablet, desktop) {
		flex: 0 1 25%;
        height: 95px;
	}

	@include mq($from: desktop) {
		flex: 0 1 12%;
        height: 95px;
	}
}

.buttonsList {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
	flex: 0 1 60%;
	//display: flex;
	//flex-wrap: wrap;
	//justify-content: center;
    display: block;
    text-align: right;

	@include mq(desktop, wide) {
		flex: 0 1 58%;
		justify-content: flex-start;
	}
	@include mq(wide) {
		flex: 0 1 45%;
	}
	> * {
		flex: 1 1 10%;
		@include mq(desktop, wide) {
			flex: 0 1 20%;
		}
	}
	> li {
		text-align: center;
		padding: relative;
        display: inline-block;
        margin-left: 30px;

        @include mq(mobile, tablet) {
            margin-left: 30px;
        }

        @include mq(tablet, desktop) {
            margin-left: 30px;
        }

        @include mq(desktop, wide) {
            margin-left: 30px;
        }

        @include mq(wide) {
            margin-left: 30px;
        }

		> a {
			color: #ffffff;
			font-size: 15px;
			font-weight: 600;
			text-decoration: none;
			padding-bottom: 1rem;
			position: relative;
			@include mq(desktop, wide) {
				font-size: 14px;
			}
			@include mq(wide) {
				font-size: 15px;
			}
			&:hover .subMenuContent {
				display: block;
			}

			&:hover, &:focus {
				color: #ffffff;
				font-weight: 600;
			}
		}
	}
}

.optionsList {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	gap: 1.5rem;
	@include mq(mobile, desktop) {
		display: block;
		margin-top: 4rem;
	}

	@include mq(desktop, wide) {
		gap: 0.5rem;
	}

	> li {
		@include mq(mobile, tablet) {
			margin-bottom: 1rem;
		}
        @include mq(tablet, desktop) {
            display: block;
            margin-bottom: 1rem;
        }
		> a {
			@include mq(mobile, tablet) {
				display: block;
			}
			@include mq(tablet, desktop) {
				display: block;
			}
		}
	}
}

.buttonOptionMenu {
	font-size: 15px;
	padding: 0.4rem 1rem;

	@include mq(desktop, wide) {
		padding: 0.4rem 1rem;
		font-size: 0.8rem;
	}

}

/* Submenu */

.subMenuContent {
	display: none;
	position: absolute;
	left: 0;
	top: 1.5625rem;
	background-color: $white;
	border: 0.0625rem solid $borderGrey;
	width: 12.5rem;
	z-index: 2;
	> ul {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
		> li {
			border-bottom: 1px solid $borderGrey;
			transition: 0.3s all ease-in-out;
			&:hover {
				background: $bgSubMenu;
			}
			&:last-of-type {
				border-bottom: 0;
			}
			> a {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				padding: 0.5rem 1.375rem;
				color: $textGrey;
				text-decoration: none;
				font-size: 1rem;
				text-align: left;
				font-weight: 400;
				> svg {
					color: $orange;
					font-weight: 700;
				}
			}
		}
	}
}

.displaySubSubMenu {
	position: relative;
	display: block;

	&:hover .subSubMenuContent {
		display: block;
	}
}

.menuIcon {
	margin-left: 5px;
}

.displaySubSubSubMenu {
	position: relative;
	display: flex !important;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	> svg {
		color: $orange;
		font-weight: 700;
	}
	&:hover .subSubSubMenuContent {
		display: block;
	}
}

.subSubMenuContent {
	display: none;
	position: absolute;
	top: -1px;
	left: 12.35rem;
	background-color: $white;
	border: 0.0625rem solid $borderGrey;
	//border-radius: 0 1.25rem 1.25rem 1.25rem;
	width: 100%;
	z-index: 1;
	> a {
		display: block;
		padding: 0.5rem 1.375rem;
		color: $textGrey;
		text-decoration: none;
		font-size: 1rem;
		text-align: left;
		font-weight: 400;
		border-bottom: 1px solid $borderGrey;
		&:last-of-type {
			border-bottom: 0;
		}

		&:hover {
			background: $bgSubMenu;
		}
	}
}

.subSubMenuContent .subSubSubMenuContent:empty {
	display: none;
}

.subSubSubMenuContent {
	display: none;
	position: absolute;
	top: -1px;
	left: 12.25rem;
	background-color: $white;
	border: 0.0625rem solid $borderGrey;
	width: 100%;
	z-index: 1;
	> a {
		display: block;
		padding: 0.5rem 1.375rem;
		color: $textGrey;
		text-decoration: none;
		font-size: 1rem;
		text-align: left;
		font-weight: 400;
		border-bottom: 1px solid $borderGrey;
		&:last-of-type {
			border-bottom: 0;
		}

		&:hover {
			background: $bgSubMenu;
		}
	}
}

// Inner news
.innerNewsWrapper {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	> h1 {
		font-size: 3rem;
		font-weight: 700;
		color: #333;
		margin-bottom: 2rem;
        @include mq(mobile, tablet) {
            font-size: 2rem;
        }
	}
}

.innerNewsImage {
	display: block;
	width: 100%;
}

.shareButtonsWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5rem;
	margin: 1rem 0;

	> * {
		flex: 0 1;
	}
	> span {
		font-weight: 500;
		color: #666;
	}
}

.mobile-menu-logo {
	width: 60%;
	padding: 0.7rem;
}

.offcanvas-mobile {
	max-width: 85%;
}

.menu-list-mobile {
	> li {
		border-bottom: 1px solid #3b723b;
		margin-bottom: 0.6rem;
	}
}

.menu-list-link {
	color: #3b723b;
	font-size: 1rem;
	display: block;
	padding: 10px;
	text-decoration: none;
	&:focus, &:active, &:hover {
		color: #3b723b;
	}
}

.accordion-menu-mobile {
	> button {
		border-radius: 0 !important;
		border: 0 !important;
		padding: 10px;
		color: #3b723b;
		font-size: 1rem;
		&:active, &:hover, &:focus {
			box-shadow: none !important;
		}
		&:not(.collapsed) {
			color: #3b723b;
			background-color: white;
			font-size: 1rem;
			box-shadow: none;
			&::after {
				background-image: var(--bs-accordion-btn-icon);
			}
		}
	}
}

.accordion-item-menu-mobile {
	border-radius: 0 !important;
	border: 0 !important;
}

.btn-user-menu {
	 &.btn.show {
		border: none;
		color: white;
	}
	&:first-child:active {
		border: none;
		color: white;
	}
	color: white;
	font-weight: 600;
	font-size: 1rem;
	&:hover, &:focus, &:active {
		border: none;
		color: white;
	}
}

.dropdown-user-menu {
	padding-top: 0;
	padding-bottom: 0;
	> li {
		border-bottom: 1px solid #ccc;
		&:last-of-type {
			border-bottom: none !important;
		}
	}

	> li > a {
		color: $green;
		text-decoration: none;
		padding: 10px;
		display: block;
		font-size: 0.9rem;
		> svg {
			margin-right: 5px;
		}
		&:hover, &:focus, &:active {
			background-color: $green;
			color: white;
		}
	}
	> li > button {
		background-color: transparent;
		border: none;
		color: $green;
		display: block;
		width: 100%;
		text-align: left;
		padding: 10px;
		font-size: 0.9rem;
		&:hover, &:focus, &:active {
			background-color: $green;
			color: white;
		}
		> svg {
			margin-right: 5px;
		}
	}
}

.welcome-mobile-menu {
	color: $green;
	text-align: center;
	margin: 2rem 0 1rem;
	font-size: 1.3rem;
	font-weight: 700;
}

.ul-list-user-mobile {
	> li {
		> button {
			background-color: transparent;
			border: none;
			color: #3b723b;
			display: block;
			width: 100%;
			text-align: left;
			padding: 10px;
			font-size: 1rem;
			> svg {
				margin-right: 5px;
			}
		}
		> a {
			color: #3b723b;
			text-decoration: none;
			padding: 10px;
			display: block;
			font-size: 1rem;
			> svg {
				margin-right: 5px;
			}
		}
	}
}

.logo-header {
    width: 180px;
    display: block;
    margin: 0 auto;
    position: absolute;
    z-index: 1;
    @include mq(mobile, tablet) {
        width: 130px;
    }
    @include mq(tablet, desktop) {
        width: 150px;
    }
}
