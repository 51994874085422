.py-map-comercio {
	display: block;
	width: 100%;
}

.perfil-pais-text {
	color: #646B6E;
	@include mq(mobile, tablet) {
		font-size: 1rem;
	}

	@include mq(tablet, desktop) {
		font-size: 1.1rem;
	}

	@include mq(desktop, wide) {
		font-size: 1.1rem;
	}

	@include mq(wide) {
		font-size: 1.25rem;
	}
}

.perfil-pais-block-divider {
	> div:nth-of-type(1) {
		@include mq(mobile, tablet) {
			margin-bottom: 2rem;
            padding: 2rem 1rem 0;
		}
		> h2 {
			@include mq(tablet, desktop) {
				font-size: 2rem;
			}
			@include mq(desktop, wide) {
				font-size: 2.9rem;
			}
		}
	}

	@include mq(tablet, desktop) {
		display: grid;
		grid-template-columns: 1fr;
		align-items: center;
        padding: 1.5rem 1rem 0;
	}

	@include mq(desktop, wide) {
		display: grid;
		grid-template-columns: 435px 1fr;
		align-items: center;
        padding-left: 6rem;
	}

	@include mq(wide) {
		display: grid;
		grid-template-columns: 500px 1fr;
		align-items: center;
        padding-left: 6rem;
	}
}

