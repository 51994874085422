.buttonNormal {
	padding: 10px 20px;
	font-size: 1.3rem;
	text-align: center;
	border-radius: 10px;
	font-weight: 600;
	text-decoration: none;
}


.TopProductGroupWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin-bottom: 5rem;

	@include mq(mobile, mobile-mid) {
		flex-direction: column;
		margin-bottom: 2rem;
	}

	@include mq(mobile-mid, tablet) {
		justify-content: space-between;
	}

	@include mq(desktop, wide) {
		justify-content: space-between;
	}

	> div {
		flex: 1 1 50%;
		@include mq(mobile, mobile-mid) {
			flex: 1 100%;
		}

		@include mq(mobile-mid, tablet) {
			flex: 0 1 30%;
		}

		@include mq(tablet, desktop) {
			flex: 0 1 40%;
		}

		@include mq(desktop, wide) {
			flex: 0 1 45%;
		}
	}

	> div > p {
		margin-top: 5rem;
		margin-left: 5rem;
		font-weight: 700;

		@include mq(mobile, mobile-mid) {
			margin-top: 2rem;
			margin-left: 0;
			margin-bottom: 2rem;
			color: $green;
		}

		@include mq(mobile-mid, tablet) {
			margin-top: 5rem;
			margin-left: 0;
			margin-bottom: 0;
		}

		@include mq(tablet, desktop) {
			margin-left: 0;
		}

		@include mq(desktop, wide) {
			margin-left: 0;
		}
	}

	> div > h1 {
		font-size: 4rem;
		font-weight: 700;
		color: $green;
		//margin-top: 5rem;
		margin-left: 5rem;

		@include mq(mobile, mobile-mid) {
			font-size: 3rem;
			margin-top: 2rem;
			margin-left: 0;
			margin-bottom: 2rem;
			color: $green;
		}

		@include mq(mobile-mid, tablet) {
			font-size: 3.5rem;
			margin-top: 5rem;
			margin-left: 0;
			margin-bottom: 0;
		}

		@include mq(tablet, desktop) {
			margin-left: 0;
			font-size: 3.2rem;
		}

		@include mq(desktop, wide) {
			margin-left: 0;
			font-size: 3.5rem;
		}
	}

	> .rightProductImageWrapper {
		flex: 0 1 400px;
		min-height: 520px;
		border-radius: 20px;
		//background-image: url('../images/green_bg.png');
		background-position: top left;
		background-size: cover;
		background-repeat: no-repeat;

		@include mq(mobile, mobile-mid) {
			background-size: contain;
			background-position: center;
			display: none;
		}

		@include mq(mobile-mid, tablet) {
			flex: 0 1 385px;
		}

		@include mq(tablet, desktop) {
			flex: 0 1 350px;
		}

		> img {
			display: block;
			width: 425px;
			margin-left: -115px;
			margin-top: 40px;
			object-fit: cover;
			object-position: top left;

			@include mq(mobile, mobile-mid) {
				margin-left: -60px;
				margin-top: 90px;
				width: 340px;
			}

			@include mq(mobile-mid, tablet) {
				margin-left: -95px;
				margin-top: 50px;
				width: 415px;
			}

			@include mq(tablet, desktop) {
				width: 350px;
				margin-left: -78px;
				margin-top: 82px;
			}
		}
	}
}

/* Filters */

.productFilters {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 3rem;
	@include mq(mobile, mobile-mid) {
		display: block;
	}
}

.leftFilterRow {
	flex: 0 1 20%;
	@include mq(mobile, mobile-mid) {
		margin-bottom: 1.5rem;
	}

	@include mq(mobile-mid, tablet) {
		flex: 0 1 35%;
	}
	@include mq(tablet, desktop) {
		flex: 0 1 35%;
	}
	@include mq(desktop, wide) {
		flex: 0 1 30%;
	}
	@include mq(wide) {
		flex: 0 1 30%;
	}
}

.rightFilterRow {
	flex: 0 1 40%;
	align-items: center;
	@include mq(tablet, desktop) {
		flex: 0 1 45%;
	}
}

.align-right {
	text-align: right;
}

.narrowSelectPerPage {
	@include mq($from: tablet) {
		flex: 0 1 165px;
	}
}

/* Inner Products */

.innerProductWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 3rem 0;
	@include mq(mobile, tablet) {
		flex-direction: column;
	}
	//gap: 0 1rem;
}

.leftInnerProductInformation {
    position: relative;
	flex: 0 1 50%;
}

.rightInnerProductInformation {
	flex: 0 1 50%;
	> h1 {
		font-size: 3.75rem;
		margin-bottom: 1rem;
		color: $grey;
		@include mq(mobile, tablet) {
			font-size: 2.5rem;
			margin-top: 1rem;
		}
	}
	> p:nth-of-type(1) {
		font-size: 1.5em;
		color: lighten($grey, 20%);
		> span {
			color: $grey;
		}
	}
}

.productCertifications {
	border-top: 1px solid lighten($grey, 20%);
	border-bottom: 1px solid lighten($grey, 20%);
	padding: 3rem 0;

	> h2 {
		color: $grey;
		font-size: 2.25rem;
		margin: 0 0 1em;
		font-weight: 400;
	}
}

.certificacionsWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	> * {
		flex-basis: 0 1 20%;
	}
}

.suggestedProducts {
	> h2 {
		text-align: center;
		color: $grey;
		font-size: 2.5rem;
		font-weight: 700;
		margin-top: 4rem;
		margin-bottom: 3rem;
	}
}

.product-tab-content {
	width: 100%;
		position: relative;
	> .active {
		display: grid !important;
		position: relative;
	}
}

.title-inner-product {
	font-weight: 400;
	color: $grey;
	font-size: 2rem;
	margin: 1rem 0;
}

.pres-var-product {
	border: 1px solid #d98829;
	padding: 10px;
	color: #d98829;
	border-radius: 8px;
	display: inline;
	font-weight: 600;
	font-size: 1.1rem;
}

.inner-product-ul {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	> li {
		display: inline-flex;
	}
}

.inner-product-text {
	color: #646464;
	font-weight: 400;
	font-size: 1rem;
}

.show-more-products-catalog {
	position: absolute;
	bottom: -60px;
	left: 0;
	right: 0;
}

.p-text-top-products {
	font-weight: 600;
	margin-bottom: 0;
}

.productPills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
    flex-direction: column;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}

.productPill {
    width: 70px;
    display: block;
}

.productPillInner {
    width: 100px;
    display: block;
}
