.preparacion-block {
	padding: 20px;
	border-radius: 8px;
	justify-content: center;
	gap: 15px;

	@include mq(mobile, tablet) {
		margin-bottom: 1rem;
        display: flex;
		flex-direction: column;
		justify-content: flex-start;
        padding: 15px;
        border: 1px solid #ccc;
	}

	@include mq(tablet, desktop) {
		display: grid;
		grid-template-columns: 0.3fr 2fr 1fr;
        gap: 3rem;
        padding: 0;
        align-items: center;
	}

	@include mq(desktop, wide) {
		display: grid;
		grid-template-columns: 10% 50% 15%;
        align-items: center;
	}

	@include mq(wide) {
		display: grid;
		grid-template-columns: 10% 50% 15%;
        align-items: center;
	}

	p {
		margin-bottom: 0;
	}
	p:nth-of-type(1) {
		color: #666666;
		font-weight: 700;
        text-align: center;
		@include mq(mobile, tablet) {
            font-size: 50px;
		}
        @include mq(tablet, desktop) {
            font-size: 50px;
        }
		@include mq(desktop, wide) {
            font-size: 88px;
		}
        @include mq(wide) {
            font-size: 88px;
        }
	}

	p:nth-of-type(2) {
		color: $grey;
		font-size: 1.5rem;
		@include mq(mobile, tablet) {
            font-size: 22px;
		}
		@include mq(tablet, desktop) {
            font-size: 23px;
		}
		@include mq($from: desktop) {
            font-size: 27px;
		}
	}

	img {
		display: block;
		@include mq(mobile, tablet) {
			width: 220px;
            margin-left: auto;
            margin-right: auto;
		}

		@include mq(tablet, desktop) {
			width: 220px;
            margin-left: auto;
            margin-right: auto;
		}

        @include mq($from: desktop) {
            width: 250px;
            margin-left: 2rem;
        }
	}
}

.preparar-steps-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.preparar-mate-wrapper {
	margin-top: 6rem;
	h2 {
		text-align: center;
		margin: 2rem 0 5rem;
		@include mq(mobile, tablet) {
			font-size: 2rem;
			margin: 2rem 0 2rem;
		}
		@include mq(tablet, desktop) {
			font-size: 2.5rem;
			margin: 2rem 0;
		}
		@include mq($from: desktop) {
			font-size: 3.75rem;
		}
	}
}

.yerba-mate-story {
	h2 {
		text-align: center;
		@include mq(mobile, tablet) {
			width: 100%;
		}

		@include mq(tablet, desktop) {
			width: 70%;
			margin: 0 auto;
		}

		@include mq(desktop, wide) {
			width: 100%;
		}

		@include mq(wide) {
			width: 75%;
			margin: 0 auto;
		}
	}
	img {
		display: block;
		margin: 0 auto;
		@include mq(mobile, wide) {
			margin: 0 auto 2rem;
			width: 100%;
		}
		@include mq(wide) {
			height: 85%;
		}
	}
}

.nav-yerba-mate {
	@include mq(mobile, tablet) {
		justify-content: space-around;
		gap: 1rem;
	}
}

.tab-cultivo {
	background-image: url('../images/yerba_mate/Cultivo-Naranja.png');
	background-size: cover;
	background-color: #d9882940;
	width: 80px;
	height: 80px;
	padding: 10px;
	border-radius: 100%;
	position: relative;
	margin: 0 auto 10px;
	transition: 0.3s all ease-in-out;

	&:hover {
		background-image: url('../images/yerba_mate/Cultivo-Blanco.png');
		background-color: $orange;
		transform: scale(1.2);
	}
}

.tab-cultivo.active {
	background-image: url('../images/yerba_mate/Cultivo-Blanco.png');
	background-color: $orange;
	transform: scale(1.2);
}

.tab-cosecha {
	background-image: url('../images/yerba_mate/Cosecha-naranja.png');
	background-size: cover;
	background-color: #d9882940;
	width: 80px;
	height: 80px;
	padding: 10px;
	border-radius: 100%;
	margin: 0 auto 10px;
	transition: 0.3s all ease-in-out;
	&:hover {
		background-image: url('../images/yerba_mate/Cosecha-Blanco.png');
		background-color: $orange;
		transform: scale(1.2);
	}

}
.tab-cosecha.active {
	background-image: url('../images/yerba_mate/Cosecha-Blanco.png');
	background-color: $orange;
	transform: scale(1.2);
}

.tab-sapecado {
	background-image: url('../images/yerba_mate/Sapecado-Naranja.png');
	background-size: cover;
	background-color: #d9882940;
	width: 80px;
	height: 80px;
	padding: 10px;
	border-radius: 100%;
	margin: 0 auto 10px;
	transition: 0.3s all ease-in-out;
	&:hover {
	background-image: url('../images/yerba_mate/Sapecado-Blanco.png');
	background-color: $orange;
	transform: scale(1.2);
	}

}
.tab-sapecado.active {
	background-image: url('../images/yerba_mate/Sapecado-Blanco.png');
	background-color: $orange;
	transform: scale(1.2);
}

.tab-secado {
	background-image: url('../images/yerba_mate/Secado-Naranja.png');
	background-size: cover;
	background-color: #d9882940;
	width: 80px;
	height: 80px;
	padding: 10px;
	border-radius: 100%;
	margin: 0 auto 10px;
	transition: 0.3s all ease-in-out;
	&:hover {
		background-image: url('../images/yerba_mate/Secado-Blanco.png');
		background-color: $orange;
		transform: scale(1.2);
	}

}
.tab-secado.active {
	background-image: url('../images/yerba_mate/Secado-Blanco.png');
	background-color: $orange;
	transform: scale(1.2);
}

.tab-estacionado {
	background-image: url('../images/yerba_mate/Estacionado-Naranja.png');
	background-size: cover;
	background-color: #d9882940;
	width: 80px;
	height: 80px;
	padding: 10px;
	border-radius: 100%;
	margin: 0 auto 10px;
	transition: 0.3s all ease-in-out;
	&:hover {
		background-image: url('../images/yerba_mate/Estacionado-Blanco.png');
		background-color: $orange;
		transform: scale(1.2);
	}

}
.tab-estacionado.active {
	background-image: url('../images/yerba_mate/Estacionado-Blanco.png');
	background-color: $orange;
	transform: scale(1.2);
}

.tab-molienda {
	background-image: url('../images/yerba_mate/Molienda-Naranja.png');
	background-size: cover;
	background-color: #d9882940;
	width: 80px;
	height: 80px;
	padding: 10px;
	border-radius: 100%;
	margin: 0 auto 10px;
	transition: 0.3s all ease-in-out;
	&:hover {
		background-image: url('../images/yerba_mate/Molienda-Blanco.png');
		background-color: $orange;
		transform: scale(1.2);
	}

}
.tab-molienda.active {
	background-image: url('../images/yerba_mate/Molienda-Blanco.png');
	background-color: $orange;
	transform: scale(1.2);
}

.tab-envasado {
	background-image: url('../images/yerba_mate/Envasado-Naranja.png');
	background-size: cover;
	background-color: #d9882940;
	width: 80px;
	height: 80px;
	padding: 10px;
	border-radius: 100%;
	margin: 0 auto 10px;
	transition: 0.3s all ease-in-out;
	&:hover {
		background-image: url('../images/yerba_mate/Envasado-Blanco.png');
		background-color: $orange;
		transform: scale(1.2);
	}

}
.tab-envasado.active {
	background-image: url('../images/yerba_mate/Envasado-Blanco.png');
	background-color: $orange;
	transform: scale(1.2);
}

.metodos-yerba-mate-wrapper {
	img {
		display: block;
		width: 60%;
		margin: 0 auto;
	}
	@include mq(mobile, tablet) {
		display: block;
	}
	@include mq($from: tablet) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
}

.yerba-mate-success-block {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
    grid-gap: 1.3rem;
    justify-content: center;
    margin-top: 3rem;
    position: relative;
    @include mq(mobile, tablet) {
        grid-template-columns: 1fr;
    }

    @include mq(tablet, desktop) {
        grid-template-columns: 1fr 1fr;
    }
}

.yerba-mate-success {
    border-radius: 8px;
    padding: 20px;
    position: relative;
}

.number-success{
    color: $white;
    font-weight: 600;
    z-index: 1;
    position: relative;

    @include mq(mobile, tablet) {
        font-size: 40px;
    }
    @include mq(tablet, desktop) {
        font-size: 40px;
    }
    @include mq(desktop, wide) {
        font-size: 45px;
    }
    @include mq(wide) {
        font-size: 50px;
    }
}

.text-yerba-success {
    color: $white;
    font-family: 'Cabin';
    font-weight: 500;
    z-index: 1;
    position: relative;
    text-align: center;

    @include mq(mobile, desktop) {
        font-size: 20px;
    }
    @include mq($from: desktop) {
        font-size: 20px;
    }
}

.bg-success-1, .bg-success-2, .bg-success-3, .bg-success-4 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.bg-success-1 {
    background-image: url('../images/success_1.jpg');
}

.bg-success-2 {
    background-image: url('../images/success_2.jpg');
}

.bg-success-3 {
    background-image: url('../images/success_3.jpg');
}

.bg-success-4 {
    background-image: url('../images/success_4.jpg');
}

.layer-block-orange {
    border-radius: 8px;
    background-color: #C2792499;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}

.slider-text-yerba-mate {
    position: absolute;
    top: 200px;
    left: 0;
    width: 50%;
    @include mq(mobile, tablet) {
        position: relative;
        top: 0;
        margin: 0 auto;
        width: 95%;
    }
    @include mq(tablet, desktop) {
        position: relative;
        top: 0;
        margin: 0 auto;
        width: 70%;
    }
    @include mq($from: desktop) {
        position: absolute;
        top: 92px;
        left: 0;
        right: 0;
        margin: auto;
        width: 50%;
    }
}

.slider-text {
    text-align: center;
    color: white;
    font-size: 20px;
    padding: 1.5rem;
    margin-bottom: 0;

    @include mq(mobile, tablet) {
        font-size: 16px;
        padding: 0.5rem;
    }
    @include mq(tablet, desktop) {
        font-size: 25px;
        padding: 1.5rem;
    }
    @include mq(desktop, wide) {
        font-size: 28px;
        padding: 1.5rem;
    }
    @include mq(wide) {
        font-size: 28px;
        padding: 1.5rem;
    }
}

.planta-yerba-mate-title {
    color: $white;
    font-size: 45px;
    font-weight: 700;
    font-family: 'Cabin';
    text-align: center;
    margin: 0;

    @include mq(mobile, tablet) {
        font-size: 22px;
        margin-top: 40px;
    }
    @include mq(tablet, desktop) {
        font-size: 35px;
        margin-top: 40px;
    }
    @include mq(desktop, wide) {
        font-size: 50px;
    }
    @include mq(wide) {
        font-size: 55px;
    }
}

.process-block-wrapper {
    display: grid;
    grid-gap: 1rem;
    justify-content: center;

    @include mq(mobile, tablet) {
    grid-template-columns: 1fr;
    }
    @include mq(tablet, desktop) {
    grid-template-columns: 0.5fr 0.5fr;
    }
    @include mq(desktop, wide) {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
    }
    @include mq(wide) {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
    }
}

.process-block-modal {
    position: relative;
    z-index: 1;
    > p {
        color: $white;
        @include mq(mobile, tablet) {
            font-size: 24px;
        }
        @include mq($from: desktop) {
            font-size: 25px;
        }
    }

    @include mq(mobile, desktop) {
        padding: 1.5rem;
    }

    @include mq($from: desktop) {
        padding: 3rem;
    }
    border-radius: 8px;
}

.process-block {
    position: relative;
    z-index: 1;
    > p {
        color: $white;
        @include mq(mobile, tablet) {
            font-size: 20px;
        }
        @include mq($from: desktop) {
            font-size: 20px;
        }
    }
    padding: 1.5rem;
    border-radius: 8px;
}

.process-title-modal {
    color: $white;
    font-size: 32px !important;
    font-weight: 600;
    margin: 10px 0;
}

.process-title {
    color: $white;
    font-size: 30px !important;
    font-weight: 600;
    margin: 10px 0;
}

.black-overlay {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.black-overlay-modal {
    background: rgba(0, 0, 0, 0.80);
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.bg-proceso {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-cultivo {
    background-image: url('../images/bg_cultivo.webp');
}

.bg-cosecha {
    background-image: url('../images/bg_cosecha.jpg');
}

.bg-sapecado {
    background-image: url('../images/bg_sapecado.jpg');
}

.bg-secado {
    background-image: url('../images/bg_secado.jpg');
}

.bg-estacionado {
    background-image: url('../images/bg_estacionado.jpg');
}

.bg-molienda {
    background-image: url('../images/bg_molienda.jpg');
}

.bg-envasado {
    background-image: url('../images/bg_envasado.webp');
}

.preparados-center-wrapper {
    width: 50%;
    margin: 0 auto;
    @include mq(mobile, tablet) {
        width: 100%;
    }
    @include mq(tablet, desktop) {
        width: 100%;
    }
}

.bg-planta-yerba {
    background-image: url('../images/yerba_mate_full_img.png');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;

    @include mq(mobile, tablet) {
        min-height: 250px;
    }
    @include mq(tablet, desktop) {
        min-height: 540px;
    }
    @include mq($from: desktop) {
        min-height: 540px;
    }
}
