.recetas-title {
	@include mq(mobile, tablet) {
		width: 90%;
		text-align: center;
	}

	@include mq(tablet, desktop) {
		width: 70%;
	}

	@include mq(desktop, wide) {
		width: 80%;
	}

	@include mq(wide) {
		width: 80%;
	}
}

.filter-recipes-wrapper {
	display: flex;
	justify-content: space-between;

	@include mq(mobile, tablet) {
		flex-wrap: wrap;
		gap: 1rem;
	}

	@include mq($from: desktop) {
		flex-wrap: wrap;
	}
}

.filter-product-recipe {

	@include mq(mobile, tablet) {
		flex: 1;
	}

	@include mq(tablet, desktop) {}
	@include mq(desktop, wide) {
		flex: 0 1 20%;
	}
	@include mq(wide) {
		flex: 0 1 20%;
	}
}

.search-recipe-group {
	@include mq(mobile, tablet) {}
	@include mq(tablet, desktop) {
		flex: 0 0 60%;
		justify-content: flex-end;
	}
	@include mq(desktop, wide) {
		flex: 0 0 60%;
		justify-content: flex-end;
	}
	@include mq(wide) {
		flex: 0 1 40%;
	}
}

.recipe-grid-wrapper {
	display: grid;
	grid-gap: 2rem;
	margin-bottom: 3rem;

	@include mq(mobile, tablet) {
		grid-template-columns: 1fr;
	}
	@include mq(tablet, desktop) {
		grid-template-columns: 1fr 1fr;
	}
	@include mq(desktop, wide) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@include mq(wide) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

.link-img-recipe {
	overflow: hidden;
	max-height: 250px;
}

.link-img-index-recipe {
	overflow: hidden;
	max-height: 390px;
}

.recipe-index {
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
    background-color: #FFF;

	&:hover > a > img {
		transform: scale(1.1);
	}

	&:hover > a > h3 {
		color: darken($grey, 25%);
	}

	&:hover > p {
		color: darken($grey, 25%);
	}

	> a > img {
		width: 100%;
		display: block;
		margin: 0 auto 1rem;
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
	}

	> a {
		text-decoration: none;
	}


	> a > h3 {
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;

		color: $grey;
		font-size: 1.2rem;
		font-weight: 600;
		margin-top: 1rem;
	}

	> p {
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
		font-size: 1.1rem;
		font-weight: 400;
		color: $grey;
	}

	border-right: 1px solid lighten($grey, 40%);
	border-left: 1px solid lighten($grey, 40%);
	margin-left: -1px;
	margin-bottom: 3rem;
	padding: 0 0 1rem 0;

	@include mq($from: desktop) {
		flex: 0 1 25%;
	}
}

.recipe {
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
    background-color: white;

	&:hover > a > img {
		transform: scale(1.1);
        border-radius: 10px;
	}

	&:hover > a > h3 {
		color: darken($grey, 25%);
	}

	&:hover > p {
		color: darken($grey, 25%);
	}

	> a > img {
		width: 100%;
		display: block;
		margin: 0 auto 1rem;
        border-radius: 10px;
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
	}

	> a {
		text-decoration: none;
        border-radius: 10px;
	}


	> a > h3 {
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;

		color: $grey;
		font-size: 1.2rem;
		font-weight: 600;
		margin-top: 1rem;
	}

	> p {
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
		font-size: 1.1rem;
		font-weight: 400;
		color: $grey;
	}

	border: 1px solid lighten($grey, 40%);
    border-radius: 10px;
	margin-left: -1px;
	margin-bottom: 3rem;
	padding: 13px;
    @include mq(mobile, tablet) {
        margin-bottom: 0;
    }
	@include mq($from: desktop) {
		flex: 0 1 25%;
	}
}

.bg-other-recipe {
    background-image: url('../images/bg_other_recipes.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    padding: 2rem 0;
}

.other-recipe-grid-wrapper {
    display: grid;
    grid-gap: 1.2rem;

    @include mq(mobile, tablet) {
        grid-template-columns: 1fr;
    }
    @include mq(tablet, desktop) {
        grid-template-columns: 1fr 1fr;
    }
    @include mq($from: desktop) {
        grid-template-columns: repeat(4,1fr);
    }
}

.other-recipe-title {
    @include mq(mobile, desktop) {
        font-size: 2.2rem;
    }
    @include mq($from: desktop) {
    font-size: 2.8rem;
    }
}

.recipe-message-title {
    text-transform: uppercase;
    color: $green;
    padding-bottom: 10px;
    border-bottom: 2px solid $green;
    margin-bottom: 3rem;
    @include mq(mobile, desktop) {
        font-size: 1.5rem;
    }
    @include mq($from: desktop) {
        font-size: 1.9rem;
    }
}
