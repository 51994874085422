@import "~bootstrap/scss/bootstrap";
@import 'mq';
@import 'variables';
@import 'Header';
@import 'Footer';
@import 'Catalog';
@import 'Product';
@import 'News';
@import 'User';
@import 'Home';
@import 'AboutUs';
@import 'Social';
@import 'YerbaMate';
@import 'Contact';
@import 'ComercioExterior';
@import 'Distribuidores';
@import 'Turismo';
@import 'Recetas';

* {
    font-family: $font-primary;
}

.globalContainer {
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
}

i {
    @include mq(mobile, tablet) {
        font-size: 0.8125rem;
    }
}

html, body {
    min-height: 100vh;
    box-sizing: border-box;
    padding: 0 !important;
    margin: 0;
}

a, button {
    transition: 0.3s all ease-in-out;
}

.greenTitle {
    font-weight: 700;
    color: $green;
    text-transform: uppercase;

    @include mq(mobile, tablet) {
        font-size: 2rem;
    }

    @include mq(tablet, desktop) {
        font-size: 2.8rem;
    }
    @include mq($from: desktop) {
        font-size: 3rem;
    }
}

.show-more-button-orange {
    color: white;
    font-weight: 700;
    font-size: 1rem;
    padding: 10px 30px;
    border-radius: 8px;
    text-decoration: none;
    background-color: $orange;
    transition: 0.3s all ease-in-out;
    border: none;

    > svg {
        margin-right: 5px;
    }
    &:hover, &:focus {
        background-color: darken($orange, 10%);
        color: white;
    }
}

.grey-title {
    font-size: 3.75rem;
    font-weight: 700;
    color: $grey;
    text-transform: uppercase;
    @include mq(mobile, tablet) {
        font-size: 1.7rem;
    }

    @include mq(tablet, desktop) {
        font-size: 2.8rem;
    }
}

.h2-title-double {
    @include mq(mobile, tablet) {
        font-size: 2rem;
    }

    @include mq(tablet, desktop) {
        font-size: 2.5rem;
    }

    @include mq($from: desktop) {
        font-size: 3.75rem;
    }
}

.clearfix::after {
    content: '';
    clear: both;
    display: block;
}

.orangeTitle {
    font-size: 3.75rem;
    font-weight: 700;
    color: $orange;
    text-transform: uppercase;
    @include mq(mobile, tablet) {
        font-size: 1.7rem;
    }

    @include mq(tablet, desktop) {
        font-size: 2.8rem;
    }
}
.pages-slider {
    margin-top: 2rem;
    margin-bottom: 3rem;

    .swiper-slide {
        img {
            display: block;
            width: 100%;
        }
    }

    @include mq(mobile, tablet) {
        height: 225px;
        width: 100%;
        position: relative;
    }

    @include mq(tablet, desktop) {
        height: 480px;
        width: 100%;
    }

    @include mq(desktop, wide) {
        height: 680px;
        width: 100%;
    }

    @include mq(wide) {
        height: 800px;
        width: 100%;
    }
}

.swiper-button-next, .swiper-button-prev {
    font-weight: 700;
}

.centered-text {
    @include mq(mobile, tablet) {
        width: 95%;
        margin: 0 auto;
    }

    @include mq(tablet, desktop) {
        width: 80%;
        margin: 0 auto;
    }

    @include mq($from: desktop) {
        width: 80%;
        margin: 0 auto;
    }
}

.swiper-slide-thumb-active {
    opacity: 1 !important;
}

.top-slider {
    @include mq(mobile, tablet) {
        height: 290px;
        width: 100%;
        position: relative;
    }

    @include mq(tablet, desktop) {
        height: 500px;
        width: 100%;
    }

    @include mq(desktop, wide) {
        height: 780px;
        width: 100%;
    }

    @include mq(wide) {
        height: 800px;
        width: 100%;
    }
}

.top-slider-thumbs {
    .swiper-slide {
        display: flex;
        width: 200px;
        cursor: pointer;
        opacity: 0.5;
        overflow: hidden;
    }
    &-wrapper {
        width: 100%;
        padding-top: 100%;
        overflow: hidden;
        position: relative;
        img {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    @include mq(mobile, tablet) {
        height: 50px;
        width: 100%;
        position: relative;
    }
    @include mq(tablet, desktop) {
        height: 50px;
        width: 100%;
        position: relative;
    }
    @include mq($from: desktop) {
        height: 90px;
        width: 100%;
        position: relative;
    }
}

.background-orange-content {
    border-radius: 8px;
    background-color: #d98829;
    padding: 2rem;
    color: white;
    //	margin-top: 4rem;
    @include mq($from: desktop) {
        //		margin-top: 7rem;
    }

    > h2 {
        font-weight: 700;
        margin-bottom: 2rem;
        @include mq(mobile, tablet) {
            font-size: 24px;
            text-align: center;
        }
        @include mq($from: desktop) {
            font-size: 3rem;
        }
    }
}

.grey-text {
    color: #666666 !important;
}

h3.grey-light-text {
    @include mq(mobile, tablet) {
        font-size: 1.4rem;
    }
}

h4.grey-light-text {
    @include mq(mobile, tablet) {
        font-size: 1.2rem;
    }
    @include mq(tablet, desktop) {
        font-size: 2.2rem;
    }

    @include mq(desktop, wide) {
        font-size: 1.6rem;
    }

    @include mq(wide) {
        font-size: 2.5rem;
        font-weight: 400;
    }
    > span {
        font-weight: 600;
        font-style: italic;
    }
}

.grey-light-text {
    color: #646B6E;
    @include mq(mobile, tablet) {
        font-size: 18px;
    }

    @include mq(tablet, desktop) {
        font-size: 1.1rem;
    }

    @include mq(desktop, wide) {
        font-size: 1.2rem;
    }

    @include mq(wide) {
        font-size: 1.25rem;
    }
}

.reading-text {
    color: $grey;
    margin-bottom: 3px;
    font-weight: 400;

    @include mq(mobile, tablet) {
        font-size: 1rem;
        margin-bottom: 16px;
    }
    @include mq($from: tablet) {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }
}

.block-divider {
    > div:nth-of-type(1) {
        @include mq(mobile, tablet) {
            margin-bottom: 1.3rem;
        }
    }

    > div > p {
        font-weight: 400;
        @include mq(mobile, tablet) {
            font-size: 1rem;
        }

        @include mq(desktop, wide) {
            font-size: 1.1rem;
        }

        @include mq(wide) {
            font-size: 1.25rem;
        }
    }
    @include mq(tablet,desktop) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

    @include mq(desktop, wide) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
    }

    @include mq(wide) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

.bodyContainer {
    @include mq(mobile, tablet) {
        margin: 2rem auto;
    }
    @include mq(tablet, desktop) {
        margin: 2rem auto;
    }
    @include mq(desktop, wide) {
        margin: 2rem auto;
    }
    @include mq(wide) {
        margin: 2rem auto;
    }
}

.buttonOrange {
    background-color: $orange;
    color: $white;
    &:hover, &:focus {
        background-color: darken(orange, 10%);
        color: white;
    }
}

.image-title-section-medium {
    display: block;
    width: 30%;
    margin: 7rem auto;
    @include mq(mobile, tablet) {
        width: 100%;
        margin: 0 auto;
    }
    @include mq(tablet, desktop) {
        width: 40%;
        margin: 3rem auto;
    }
    @include mq($from: desktop) {
        width: 33%;
        margin: 0 auto 2rem;
    }
}

.imageSection {
    display: block;
    width: 100%;
    margin: 0;
    //@include mq(mobile, tablet) {
    //    width: 85%;
    //    margin: 1rem auto;
    //}
    //@include mq(tablet, desktop) {
    //    width: 50%;
    //    margin: 3rem auto;
    //}
    //@include mq($from: desktop) {
    //    width: 500px;
    //    margin: 0 auto 2rem;
    //}
}

.button {
    text-align: center;
    border-radius: 5px;
    font-weight: 500;
    text-decoration: none;
}

.buttonOrangeReverse {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
    &:hover, &:focus {
        background-color: $orange;
        color: $white;
        border: 1px solid $orange;
    }

    @include mq(mobile, desktop) {
        background-color: transparent;
        color: $orange;
        border: 1px solid $orange;

    }
}

.tab-content-wrapper {
    margin-top: 2.5rem;
    h3 {
        font-weight: 700;
        @include mq($from: desktop) {
            font-size: 2.5rem;
        }
    }
    p {
        @include mq($from: desktop) {
            font-size: 1.5rem;
        }
    }
}

.green-text {
    color: $green;
}

.italic-text {
    font-style: italic;
}

.block-light-spacing {
    @include mq(mobile, tablet) {
        margin-top: 3rem;
    }
    @include mq($from: tablet) {
        margin-top: 5rem;
    }
}

.block-spacing {
    @include mq(mobile, tablet) {
        margin-top: 5rem;
    }
    @include mq(tablet, desktop) {
        margin-top: 3rem;
    }
    @include mq($from: desktop) {
        margin-top: 8rem;
    }
}

.btn-close {
    &:hover, &:active, &:focus {
        box-shadow: none !important;
    }
}

.light-orange-block-wrapper {
    background-color: #F7ECE4;
    border-radius: 8px;

    @include mq(mobile, tablet) {
        padding: 1.5rem;
    }
    @include mq(tablet, desktop) {
        padding: 1.6rem;
    }
    @include mq(desktop, wide) {
        padding: 1.7rem;
    }
    @include mq(wide) {
        padding: 2rem;
    }
}

.rounded {
    border-radius: 8px;
}

.upper-text {
    text-transform: uppercase;
}

.light-green-text {
    color: $lightGreen;
}

.light-green-title {
    color: $green;
    font-weight: 400;
    font-family: 'Cabin';

    @include mq(mobile, tablet) {
        font-size: 20px;
    }
    @include mq(tablet, desktop) {
        font-size: 27px;
    }
    @include mq(desktop, wide) {
        font-size: 30px;
    }
    @include mq(wide) {
        font-size: 35px;
    }
}

.greenTitle.green-big-title {
    @include mq(desktop, wide) {
        font-size: 3rem;
    }
}
.green-big-title-turismo {
    @include mq(mobile, tablet) {
        margin-left: auto;
        margin-right: auto;
    }
}

.green-big-title {
    @include mq(mobile, tablet) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    @include mq(tablet, desktop) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    @include mq(desktop, wide) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    @include mq(wide) {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
}

.flex-1 {
    flex: 1 100%;
}

.loading-spinner {
    width: 65px;
    height: 65px;
    border-width: 8px;
}

.span-error-message {
    color: red;
    display: block;
    font-size: 0.7rem;
    font-weight: 600;
    margin: 0.5rem 0;
}

.orange-button {
    background-color: $orange;
    display: block;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;

    &:hover, &:focus, &:active {
        background-color: darken($orange, 15%);
    }
}

.orange-link {
    display: block;
    color: orange;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: underline;

    &:hover, &:focus, &:active {
        text-decoration: underline;
        color: darken($orange, 15%);
    }
}

.link-images {
    text-decoration: none;
}

.image-grid {
    object-fit: cover;
    object-position: center;

    @include mq(mobile, tablet) {
        height: inherit;
    }
    @include mq($from: tablet) {
        height: 15.625rem;
    }
}

.image-index-grid {
    object-fit: cover;
    object-position: center;

    @include mq(mobile, tablet) {
        height: inherit;
    }
    @include mq($from: tablet) {
        height: 19.625rem;
    }
}

.image-inner-content {
    object-fit: cover;
    object-position: center;

    @include mq(mobile, tablet) {
        height: inherit;
    }
    @include mq($from: tablet) {
        height: 37.5rem;
    }
}

.alert-block-wrapper {
    margin: 0 auto;
    @include mq(mobile, tablet) {
        width: 90%;
    }
    @include mq(tablet, desktop) {
        width: 90%;
    }
    @include mq($from: desktop) {
        width: 80%;
    }
}

.error-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
}

.h1-error-text {
    text-align: center;
}

.loading-wrapper {
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text-mobile-center {
    @include mq(mobile, tablet) {
        text-align: center;
    }
}

.whatsapp-button {
    width: 60px;
    height: 60px;
    background-color: #0dc143;
    position: fixed;
    bottom: 48px;
    right: 20px;
    border-radius: 50%;
    z-index: 999;

    @include mq(mobile, desktop) {
        bottom: 93px;
    }

    > a {
        text-decoration: none;
    }
    > a > img {
        width: 100%;
        transform: scale(.8);
        margin: auto;
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;

        &:hover {
            transform: scale(.9);
        }
    }
}

.bread-content {
    @include mq(mobile, tablet) {
        padding: 0 1rem;
    }
    @include mq(tablet, desktop) {
        padding: 0 2rem;
    }
    @include mq(desktop, wide) {
        padding: 0 6rem;
    }
    @include mq(wide) {
        padding: 0 12rem;
    }
}

.big-leaf-left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    @include mq(mobile, tablet) {
        width: 100px;
        left: -220px;
        top: -42px;
    }
    @include mq(tablet, desktop) {
        width: 300px;
        left: -220px;
        top: -42px;
    }
    @include mq(desktop, wide) {
        width: 500px;
        left: -220px;
        top: -42px;
    }
    @include mq(wide) {
        width: 500px;
        left: -220px;
        top: -42px;
    }
}

.big-leaf-right {
    position: absolute;
    z-index: 0;
    @include mq(mobile, tablet) {
        width: 100px;
        right: -220px;
        top: -42px;
    }
    @include mq(tablet, desktop) {
        width: 300px;
        right: -220px;
        top: -42px;
    }
    @include mq(desktop, wide) {
        width: 500px;
        right: -220px;
        top: -42px;
    }
    @include mq(wide) {
        width: 500px;
        right: -220px;
        top: -42px;
    }
}

.lonely-left-leaf {
    position: absolute;
    z-index: 1;

    @include mq(mobile, tablet) {
        width: 300px;
        left: -330px;
        top: -42px;
    }
    @include mq(tablet, desktop) {
        width: 300px;
        left: -330px;
        top: -42px;
    }
    @include mq(desktop, wide) {
        width: 800px;
        left: -330px;
        top: -42px;
    }
    @include mq(wide) {
        width: 800px;
        left: -330px;
        top: -42px;
    }
}

.lonely-right-leaf {
    position: absolute;
    z-index: 1;
    rotate: 180deg;

    @include mq(mobile, tablet) {
        width: 300px;
        right: -330px;
        top: -42px;
    }
    @include mq(tablet, desktop) {
        width: 300px;
        right: -330px;
        top: -42px;
    }
    @include mq(desktop, wide) {
        width: 800px;
        right: -330px;
        top: -42px;
    }
    @include mq(wide) {
        width: 800px;
        right: -330px;
        top: -42px;
    }
}

.color-white {
    color: $white;
}

.text-bold {
    font-weight: 700;
}

.minor-h3-title {
    font-size: 2rem;
    color: $grey;
    font-weight: 700;
    margin-bottom: 30px;
    @include mq(mobile, tablet) {
        margin-bottom: 12px;
    }
}

.bg-light-grey {
    background-color: rgb(246, 249, 243);
}

.bg-complete-leaf {
    background-image: url('../images/bg_turismo_1.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    width: 100%;
}

.image-turismo-banner {
    margin-top: 10rem;
    @include mq(mobile, desktop) {
        margin-top: 0;
    }
}

.simple-banner-wrapper {
    position: relative;
}

.mt-6 {
    @include mq(mobile, desktop) {
        margin-top: 3rem;
    }
    @include mq($from: desktop) {
        margin-top: 4.5rem;
    }
}

.mt-7 {
    @include mq(mobile, desktop) {
        margin-top: 4rem;
    }
    @include mq($from: desktop) {
        margin-top: 7rem;
    }
}

.mb-6 {
    @include mq(mobile, desktop) {
        margin-bottom: 3rem;
    }
    @include mq($from: desktop) {
        margin-bottom: 4.5rem;
    }
}

.mb-7 {
    @include mq(mobile, desktop) {
        margin-bottom: 4rem;
    }
    @include mq($from: desktop) {
        margin-bottom: 7rem;
    }
}

.splide-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-wrapper {
    padding: 2rem 4rem;
    @include mq(mobile, tablet) {
        padding: 2rem 3rem;
    }
}

.slider-wrapper-text {
    padding: 2rem 4rem;
    @include mq(mobile, tablet) {
        padding: 1rem;
    }
    @include mq(tablet, desktop) {
        padding: 1.5rem;
    }
}

.arrow-slider-wrapper {
    background-color: transparent !important;
}

.arrow-left-slider, .arrow-right-slider {
    width: 40px;
    height: 40px;
    display: block;
    object-fit: cover;
}

.text-in-blocks {
    @include mq(mobile, tablet) {
        font-size: 18px;
    }
    @include mq(wide) {
        font-size: 23px;
    }
    @include mq(wide) {
        font-size: 23px;
    }
    @include mq(wide) {
        font-size: 23px;
    }
    @include mq(wide) {
        font-size: 23px;
    }
    > p {
        margin-bottom: 10px;
    }
}

.two-block-text-wrapper {
    display: grid;
    margin-bottom: 2rem;
    justify-content: center;

    @include mq(mobile, tablet) {
        grid-template-columns: 1fr;
        grid-gap: 0.5rem;
        margin-bottom: 0;
    }
    @include mq(tablet, desktop) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        margin-bottom: 0;
    }

    @include mq($from: desktop) {
        grid-template-columns: 0.45fr 0.4fr;
        grid-gap: 5rem;
    }
    > img {
        width: 100%;
        margin: 0 auto;
        display: block;
    }
}

.z1 {
    z-index: 1;
}

.btn-close-modal {
    position: absolute;
    right: 55px;
    color: white;
    font-size: 25px;
    background: transparent;
    border: none;
    @include mq(mobile, desktop) {
        right: 25px;
    }
}

.btn-show-more-modal {
    color: $orange;
    background: transparent;
    text-decoration: underline;
    font-size: 20px;
    border: none;
    font-weight: 600;

    &:focus, &:active, &:hover {
        color: $orange;
        text-decoration: none;
    }
}

.limit-height-text {
    overflow: hidden;
    font-size: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    @include mq($from: desktop) {
        -webkit-line-clamp: 3;
    }
}

.selecta-group-history-title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 1.3rem;
}

.selecta-group-history-text {
    @include mq(mobile, tablet) {
        font-size: 18px;
    }
    @include mq(tablet, desktop) {
        font-size: 18px;
    }
    @include mq(desktop, wide) {
        font-size: 20px;
    }
    @include mq(wide) {
        font-size: 20px;
    }
}

.img-yerba {
    position: absolute;
    right: 0;
    top: -30px;
    width: 800px;
}

.overflow-initial {
    overflow: initial !important;
}

.bread-wide-content {
    @include mq(mobile, tablet) {
        padding: 0 1rem;
    }
    @include mq(tablet, desktop) {
        padding: 0 1rem;
    }
    @include mq(desktop, wide) {
        padding: 0 10rem;
    }
    @include mq(wide) {
        padding: 0 10rem;
    }
}

.left-why-selecta-block {
    width: 90%;
    @include mq(mobile, tablet) {
        margin-left: 0;
        width: 100%;
    }
    @include mq(tablet, desktop) {
        margin-left: 3rem;
    }
    @include mq($from: desktop) {
        margin-left: 3rem;
    }

    > h2 {
        @include mq(mobile, tablet) {
        font-size: 2.2rem;
        }
        @include mq(tablet, desktop) {
        font-size: 2.2rem;
        }
        @include mq(desktop, wide) {
        font-size: 4rem;
        }
        @include mq(wide) {
        font-size: 4rem;
        }
    }
    > p {
        @include mq(mobile, tablet) {
        font-size: 20px;
        }
        @include mq(tablet, desktop) {
        font-size: 20px;
        }
        @include mq(desktop, wide) {
        font-size: 24px;
        }
        @include mq(wide) {
        font-size: 24px;
        }
    }
}

.ursula-logo {
    margin: 0 auto;
    display: block;

    @include mq(mobile, tablet) {
        width: 90%;
    }
    @include mq($from: desktop) {
        width: 30%;
    }
}

.medium-weight {
    font-weight: 600 !important;
}

.mobile-text-spacing{
    @include mq(mobile, tablet) {
        padding: 0 1rem;
    }
}

.mobile-spacing-wrapper {
    @include mq(mobile, tablet) {
        padding-top: 0 !important;
    }
}

.mobile-text-left {
    @include mq(mobile, tablet) {
        text-align: left !important;
    }
}

.modal-content, .modal-body {
    border: 1px solid #807f7f !important;
    border-radius: 8px !important;
}

.custom-modal-dialog {
    @include mq($from: desktop) {
        max-width: 60% !important;
    }
}

.title-mobile-left {
    @include mq(mobile, tablet) {
        text-align: left !important;
        padding: 0 1rem;
    }
}

.no-margin-top-mobile {
    @include mq(mobile, desktop) {
        margin-top: 0 !important;
    }
}

.no-margin-bottom-mobile {
    @include mq(mobile, desktop) {
        margin-bottom: 0 !important;
    }
}

.title-tablet {
    @include mq(tablet, desktop) {
        font-size: 2rem;
    }
}

.only-one-block {
    @include mq(tablet, desktop) {
        grid-template-columns: 1fr;
    }
}

.align-start-mobile {
    @include mq(tablet, desktop) {
        align-items: flex-start !important;
    }
}

.gap-mobile {
    @include mq(tablet, desktop) {
        gap: 2rem;
    }
}

.no-margin-bottom-wide {
    @include mq($from: desktop) {
        margin-bottom: 0 !important;
    }
}

.toast-message-container {
    position: fixed !important;
    right: 0 !important;

    @include mq($from: desktop) {
        top: 6rem !important;
    }
}

.toast-message {
    font-size: 1.1rem;
    margin-left: 10px;
}

.top-titles {
    font-size: 2.3rem;
}
