.mainFooter {
    border-top: 0.0625rem solid #E9E9E9;
    padding: 3.3rem 0 0;
    margin-top: 5rem;
    position: sticky;
    top: 100vh;
    @include mq(mobile, tablet) {
        padding: 2rem 0 4rem;
    }
}

.footerContainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;

    @include mq(mobile, tablet) {
        width: 90%;
        flex-direction: column;
        justify-content: center;
    }

    @include mq(tablet, desktop) {
        width: 80%;
        flex-direction: column;
        justify-content: center;
    }

    @include mq($from: desktop) {
        width: 80%;
        justify-content: space-between;
        align-items: center;
    }
}

.imageLogoFooterWrapper {
    flex: 0 1 17%;
    > a > img {
        display: block;
        width: 100%;
        @include mq(mobile, tablet) {
            width: 50%;
            margin: 0 auto 2rem;
        }
        @include mq(tablet, desktop) {
            width: 40%;
            margin: 0 auto 3rem;
        }
        @include mq($from: desktop) {
            width: 100%;
            margin: 0 auto;
        }
    }
    @include mq(desktop, wide) {
        flex: 0 1 20%;
    }
}

.linksFooterWrapper {
    flex: 0 1 60%;
    @include mq(desktop, wide) {
        flex: 0 1 65%;
    }
    @include mq(wide) {
        flex: 0 1 80%;
    }
}

.linksFooterList {
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: flex-end;
    display: block;
    text-align: right;
    position: relative;

    @include mq(mobile, desktop) {
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        font-size: 1.2rem;
        margin-top: 0;
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
    }
    @include mq($from: desktop) {
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    > li {
        flex: 1;
        text-align: right;
        margin: 1rem 0 0;
        display: inline-block;

        @include mq(mobile, desktop) {
            margin-top: 0;
            margin-left: 0;
        }

        @include mq(desktop, wide) {
            margin-left: 50px;
        }

        @include mq(wide) {
            margin-left: 50px;
        }
    }

    > li > a {
        text-decoration: none;
        color: $green;
        font-size: 1rem;
        font-weight: 400;
        @include mq(mobile, desktop) {
            font-size: 1.2rem;
        }

        @include mq(desktop, wide) {
            font-size: 1.1rem;
        }

        @include mq(wide) {
            font-size: 1.3rem;
        }

        &:hover, &:focus, &:active {
            text-decoration: underline;
            color: $green;
        }
    }
}

.socialMediaFooterList {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 2rem;

    @include mq(mobile, desktop) {
        justify-content: center;
        margin-top: 1rem;
    }

    @include mq($from: desktop) {
        width: 100%;
        margin: 1rem auto 0;
    }

    > li {
        flex: 0 1;
    }
}

.footerSocialButton {
    display: block;
    > svg {
        width: 2rem !important;
        fill: $green;
    }
}

.creadoresLogoWrapper {
    background: #014923;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 10px 0;
    margin-top: 2.5rem;
    > p {
        font-size: 14px;
        margin: 0;
    }
}

.creadoresLogoImage {
    width: 120px;
    display: block;
}
