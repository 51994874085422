.userSection {
	width: 30%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 4rem;
	margin-bottom: 4rem;

	> h1 {
		color: #333;
		font-size: 2.25rem;
		font-weight: 700;
		text-align: center;
		margin-bottom: 2.2rem;
	}
	> p {
		font-weight: 600;
		color: #666;
		font-size: 1rem;
		text-align: center;
		> a {
			color: $green;
			text-decoration: underline;
			&:hover, &:active, &:focus {
				color: $green;
				text-decoration: none;
			}
		}
	}
	@include mq(mobile, tablet) {
		width: 90%;
	}
	@include mq(tablet, desktop) {
		width: 50%;
	}
	@include mq($from: desktop) {
		width: 30%;
	}
}

.formUser {
	margin-top: 3rem;
	margin-bottom: 2rem;
}

.formLink {
	color: $green;
	text-decoration: underline;
	font-size: 1rem;
	font-weight: 500;
	&:hover, &:focus, &:active {
		color: $green;
		text-decoration: none;
	}
}

.btnFormLogin {
	background-color: $orange;
	color: $white;
	width: 100%;
	font-size: 1rem;
	font-weight: 500;
	border: none;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.3125rem;
	&:disabled {
		background-color: $orange !important;
		color: white !important;
	}
	&:hover, &:focus, &:active {
		background-color: darken($orange, 10%);
		color: white;
	}
}

.title-profile-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 2rem;
	@include mq(mobile, tablet) {
		flex-direction: column;
	}
}

.profile-block {
	margin: 0 0 2rem;
	> p:nth-of-type(1) {
		font-weight: 500;
		font-size: 1.1rem;
		margin: 0 0 0.6rem;
	}
	> p:nth-of-type(2) {
		color: #646B6E;
		margin: 0 0 0.6rem;
		font-size: 1rem;
		padding: 0 0 0.2rem;
		border-bottom: 1px solid #cccccc69;
	}
}
