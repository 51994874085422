.top-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    @include mq(mobile, tablet) {
        margin-bottom: 4rem;
    }
    > img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        @include mq(mobile, tablet) {
            bottom: -2px;
        }
        @include mq(tablet, desktop) {
            bottom: 50px;
        }
        @include mq(desktop, wide) {
            bottom: 50px;
        }
        @include mq(wide) {
            bottom: 50px;
        }
    }
}

.grid-video-wrapper {
    height: 0;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.grid-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.top-index-player {
    position: absolute;
    top: -65px;
    left: -2px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include mq(mobile, tablet) {
        left: 0;
        top: 0;
    }

    @include mq($from: tablet) {
        left: -2px;
    }
}

.react-player {
    position: abolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.index-content {
    > p {
        @include mq(desktop, wide) {
            font-size: 17px !important;
        }
    }
}

.index-content-wrapper {
    margin-top: 3rem;
    position: relative;
    overflow: hidden;
    @include mq(mobile, desktop) {
        margin-top: 3.5rem;
        margin-bottom: 4rem;
    }
    @include mq($from: desktop) {
        margin-top: 5rem;
        margin-bottom: 8rem;
        overflow: hidden;
    }
}

.nav-item {
    flex: 1 !important;
    @include mq(mobile, tablet) {
        flex: 0 1 30% !important;
        margin-bottom: 1rem;
    }
    @include mq(tablet, desktop) {
        flex: 0 1 25% !important;
        margin-bottom: 1rem;
    }
}

.nav-top-products {
    font-size: 16px;
    color: #C9D2C9;
    border-bottom: 5px solid transparent;
    border-radius: 0;
    text-align: left;
    font-weight: 600;
    display: inline-block;
    padding-left: 0;

    @include mq($from: desktop) {
        font-size: 24px;
        margin-bottom: 1rem;
    }
    &:hover {
        color: #3b723b;
    }

    &.active {
        border-radius: 0;
        text-align: left;
        color: #3b723b !important;
        background-color: transparent !important;
        font-size: 16px;
        border-bottom: 5px solid #3b723b !important;
        @include mq($from: desktop) {
            font-size: 24px;
        }
    }
}

.col-buttons-index-products {
    @include mq(mobile-mid, desktop) {
        width: 100% !important;
    }
}
.nav-index-products {
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #ddd;

    @include mq(mobile, mobile-mid) {
        margin-bottom: 2.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-bottom: 0;
    }
    @include mq(mobile-mid, tablet) {
        margin-bottom: 2.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 0;
    }

    @include mq($from: tablet) {
        border-bottom: 0;
    }
}

.nav-index-products.flex-row.nav {
    @include mq(mobile, tablet) {
        --bs-nav-link-padding-x: 0 !important;
    }
}

.background-index-properties {
    background-color: #d98829c9;
    //background-image: url('../images/left_leaf.png'), url('../images/right_leaf.png');
    //background-position: bottom left, right top;
    //background-size: 50%, 50%;
    //background-repeat: no-repeat, no-repeat;
    //background-attachment: fixed;
    padding: 2rem;
    position: relative;
    z-index: 1;
    color: white;
    margin-bottom: 4rem;
    opacity: 0.8;
    @include mq(desktop, wide) {
        padding: 4rem 3rem;
    }
    @include mq(wide) {
        margin-bottom: 8rem;
        padding: 6rem 12rem;
    }

    > h2 {
        font-weight: 700;
        @include mq(mobile, desktop) {
            font-size: 33px;
            text-align: center;
            margin-bottom: 1.1rem;
        }
        @include mq($from: desktop) {
            font-size: 45px;
            text-align: center;
        }
    }
    > p {
        @include mq(mobile, tablet) {
            font-size: 1.2rem;
        }

        @include mq(tablet, desktop) {
            font-size: 1.2rem;
        }

        @include mq($from: desktop) {
            font-size: 24px;
            text-align: center;
        }
    }
}

.properties-wrapper {
    display: grid;
    @include mq(mobile, mobile-mid) {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }
    @include mq(mobile-mid, tablet) {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

    @include mq(tablet, desktop) {
        grid-template-columns: 1fr 1fr;
        gap: 1.3rem;
    }
    @include mq($from: desktop) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1.5rem;
    }
}

.property-block {
    background-color: white;
    border-radius: 3px;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    color: #646B6E;
    font-weight: 600;
    text-align: center;
    transform: scale(0.9);
    transition: 0.3s all ease-in-out;

    &:hover {
        transform: scale(1);
    }

    > img {
        width: 100%;
        display: block;
    }
    @include mq(mobile, desktop) {
        font-size: 1.2rem;
    }

    @include mq(desktop, wide) {
        font-size: 20px;
    }

    @include mq(wide) {
        font-size: 20px;
    }
}

.index-grid-content {
    @include mq(desktop, wide) {
        align-items: center;
    }
}

.grid-content-wrapper {
    display: grid;
    gap: 1.5rem;
    margin-bottom: 7rem;

    @include mq(mobile, tablet) {
        margin-bottom: 0;
    }
    @include mq(tablet, desktop) {
        grid-template-columns: 1fr;
        margin-bottom: 10rem;
    }

    @include mq($from: desktop) {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 12rem;
    }
}

.two-block-wrapper {
    display: grid;
    margin-bottom: 7rem;

    @include mq(mobile, tablet) {
        grid-template-columns: 1fr;
        margin-bottom: 2rem;
        grid-gap: 1rem;
    }

    @include mq(tablet, desktop) {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 2rem;
        grid-gap: 1rem;
    }

    @include mq($from: desktop) {
        grid-template-columns: 0.5fr 0.5fr;
        margin-bottom: 5rem;
        grid-gap: 5rem;
    }
}

.grid-content {
    > p {
        color: #646B6E;
        font-size: 14px;
        font-weight: 400;
        @include mq(mobile, tablet) {
            font-size: 1.2rem;
        }
        @include mq(tablet, desktop) {
            font-size: 20px;
        }
        @include mq($from: desktop) {
            font-size: 20px;
        }
    }
}

.index-recipe-grid {
    padding: 1.3rem;
    color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mq(mobile, tablet) {
        align-items: center;
    }

    @include mq(tablet, desktop) {
        max-height: 480px;
        padding: 2rem;
        text-align: center;
        align-items: center;
    }
    @include mq($from: desktop) {
        max-height: 480px;
        padding: 2rem;
        text-align: center;
    }

    > p {
        @include mq(mobile, desktop) {
            text-align: center;
            margin-bottom: 2rem;
            font-size: 1.2rem;
        }
        @include mq($from: desktop) {
            font-size: 25px;
            margin: 2rem 0;
        }
    }
    > img {
        width: 100%;
        margin-bottom: 30px;
        @include mq(mobile, tablet) {
            width: 80%;
            margin-bottom: 36px;
            margin-top: 1rem;
            margin-left: auto;
            margin-right: auto;
        }
        @include mq(tablet, desktop) {
            width: 50%;
            margin: 0 auto 30px;
            display: block;
        }

        @include mq($from: desktop) {
            width: 70%;
            display: block;
            margin: 0 auto;
        }
    }
}

.show-more-recipe {
    border: 1px solid white;
    color: white;
    padding: 5px 20px !important;
    border-radius: 3px;
    font-size: 15px !important;
    display: inline-flex;
    text-decoration: none;
    transition: 0.3s all ease-in-out;
    &:hover, &:active, &:focus {
        background: white;
        color: $green;
    }
    @include mq($from: desktop) {
        font-size: 26px;
        padding: 8px 40px;
    }
}

.recipe-grid-index-wrapper {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;

    @include mq(mobile, tablet) {
        grid-template-columns: 1fr;
    }
}

.indexGridWrapper {
    @include mq($from: desktop) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.divided-title-block {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 0.5fr 0.5fr;
    align-items: center;

    @include mq(mobile, tablet) {
        grid-template-columns: 1fr;
        margin-bottom: 1rem !important;
    }

    @include mq(tablet, desktop) {
        grid-template-columns: 1fr 1fr;
    }

    @include mq($from: desktop) {
        grid-template-columns: 0.5fr 0.5fr;
    }

    > h2 {
        @include mq(mobile, tablet) {
            font-size: 30px;
        }
        @include mq(tablet, desktop) {
            font-size: 50px;
        }
        @include mq(desktop, wide) {
            font-size: 50px;
        }
        @include mq(wide) {
            font-size: 50px;
        }
    }
    > p,div {
        @include mq(mobile, tablet) {
            font-size: 17px;
        }
        @include mq(tablet, desktop) {
            font-size: 17px;
        }
        @include mq(desktop, wide) {
            font-size: 20px;
        }
        @include mq(wide) {
            font-size: 20px;
        }
    }
}

.p-text-normal-block {
    font-family: $font-roboto;
    color: $grey;

    @include mq(mobile, tablet) {
        font-size: 15px;
    }
    @include mq(tablet, desktop) {
        font-size: 18px;
    }
    @include mq(desktop, wide) {
        font-size: 20px;
    }
    @include mq(wide) {
        font-size: 20px;
    }
}

.bg-receta-index {
    background-image: url('../images/bg_recetas_index.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    //background-attachment: fixed;

    @include mq(mobile, tablet) {
        padding: 3rem 20px;
        background-position: center;
        background-attachment: initial;
    }
    @include mq(tablet, desktop) {
        padding: 3rem 20px;
    }
    @include mq(desktop, wide) {
        padding: 5rem 2rem;
    }
    @include mq(wide) {
        padding: 7rem 12rem;
    }
}
.two-block-wrapper-index-foreign-wrapper{
    @include mq(tablet, desktop) {
        grid-template-columns: 1fr;
    }
}
