.carouselWrapper {
	background-color: $green;
	margin-bottom: 5rem;
}

.CarouselItem {
	max-height: 630px;
	> img {
		display: block;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.h1CatalogTitle {
	color: $grey;
	font-weight: 800;
	//border-bottom: 1px solid $grey;

	text-align: center;
	@include mq(mobile, tablet) {
		font-size: 2rem;
	}
	@include mq(tablet, desktop) {
		font-size: 3rem;
	}
	@include mq(desktop, wide) {
		font-size: 3rem;
	}
	@include mq(wide) {
		font-size: 3.5rem;
	}
}

.featuredCatalogProduct {
	display: flex;
	flex-wrap: wrap;
	margin-top: 3rem;
	@include mq(mobile, tablet) {
		flex-direction: column;
	}
}

.featuredImage {
	flex: 1 1 30%;
}

.featuredInformation {
	flex: 1 1 50%;
	> h2 {
		color: $grey;
		font-size: 1.8rem;
		margin: 2rem 0 1rem;
		@include mq(mobile, tablet) {
			text-align: center;
			font-size: 1.5rem;
		}
	}
	> p {
		color: $grey;
		font-size: 1.5rem;
		margin-bottom: 3rem;
		@include mq(mobile, tablet) {
			padding: 0 10px;
			font-size: 1.3rem;
		}
	}
}

.buttonShowMore {
	border: 1px solid $orange;
	color: $orange;
	padding: 10px 22px;
	text-align: center;
	background-color: $white;
	font-weight: 500;
	text-decoration: none;
	border-radius: 5px;

	&:hover, &:focus {
		background-color: $orange;
		color: $white;
	}
}

.productLines {
	margin-top: 5rem;
	margin-bottom: 5rem;

	@include mq(mobile, tablet) {
		display: block;
	}
	@include mq($from: tablet) {
		display: flex;
		flex-wrap: wrap;
		//justify-content: center;
		align-items: center;
	}
}

.productLinesText {
	@include mq(mobile, tablet) {
		margin-bottom: 2rem;
	}

	@include mq($from: tablet) {
		flex: 1 1 50%;
		margin-bottom: 1.5rem;
	}

	> h2 {
		font-size: 2rem;
		color: $black;
		font-weight: 700;
		@include mq(mobile, tablet) {
			font-size: 1.7rem;
		}
	}
	> p {
		color: $black;
		font-size: 0.9rem;
		font-weight: 500;
	}
}

.productLinesButtons {
	@include mq(mobile, tablet) {
		//display: grid;
		//grid-template-columns: 1fr 1fr;
	}

	@include mq($from: tablet) {
		//display: flex;
		//flex-wrap: wrap;
		flex: 1 1 50%;
		//justify-content: space-evenly;
	}
}

.productLineButton.active {
	background-color: $orange !important;
	color: $white !important;
}

.productLineButton {
	color: $grey;
	padding: 10px 20px;
	text-align: center;
	border-radius: 5px;
	text-decoration: none;
	font-weight: 600;
	&:hover, &:focus {
		//background-color: $orange;
		color: $orange;
	}
	@include mq(mobile, tablet) {
		font-size: 0.8rem;
		padding: 10px 15px;
	}
}
.productGridWrapper {
	display: grid;
	gap: 1.5rem;
	margin-bottom: 4rem;

	@include mq(mobile, mobile-mid) {
		grid-template-columns: 1fr;
        margin-bottom: 1rem;
	}
	@include mq(mobile-mid, tablet) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@include mq(tablet, desktop) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@include mq(desktop, wide) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	@include mq(wide) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}


.home-recipes > .recipe {
	@include mq($from: desktop) {
		flex: 1;
	}
}


.product {
    position: relative;
	> a > img {
		width: 100%;
		display: block;
		margin: 0 auto 1rem;
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
	}

	&:hover > a > h3 {
		color: darken($grey, 25%);
	}

	&:hover > p {
		color: darken($grey, 25%);
	}

	&:hover > a > img {
		transform: scale(1.05);
	}

	> a > h3 {
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;

        font-family: $font-secondary;
		text-align: center;
		color: $grey;
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 5px;

		&:hover {
			color: $orange;
		}

	}
	> p {
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;

        font-family: $font-secondary;
		font-size: 14px;
		text-align: center;
		font-weight: 400;
		color: $grey;
	}

	border: 1px solid #EAEAEA;
    border-radius: 5px;
	margin-left: -1px;
	padding: 20px;

	@include mq(mobile, desktop) {
		margin-bottom: 1rem;
	}
}

.showProductWrapper {
	text-align: center;
}

.show-more-recipe-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.show-recipe-button {
	color: white;
	font-weight: 500;
	font-size: 1.1rem;
	padding: 4px 25px;
	border-radius: 5px;
	text-decoration: none;
	background-color: $orange;
	transition: 0.3s all ease-in-out;

	> svg {
		margin-right: 5px;
	}
	&:hover, &:focus {
		background-color: darken($orange, 10%);
		color: white;
	}
}

.recipe-timer {
	color: #646B6E;
	display: inline-block;
	> img {
		width: 30px;
	}
}

.showProductButton {
	color: $orange;
	font-weight: 700;
	margin-top: 3rem;
	font-size: 1.2rem;
	text-decoration: none;
	> svg {
		margin-right: 5px;
	}
	&:hover, &:focus {
		color: darken($orange, 10%);
	}
}

.viewMoreProductsButton {
	background-color: $orange;
	color: $white;
	padding: 10px 20px;
	font-size: 1.3rem;
	font-weight: 700;
	text-decoration: none;
	border-radius: 5px;
	&:focus, &:hover {
		background-color: darken($orange, 10%);
		color: $white;
	}
}

.nav-pills-catalogs {
	@include mq(mobile, tablet) {
		flex-wrap: wrap;
		gap: 5px;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	@include mq(tablet, desktop) {
		align-items: center;
		flex-wrap: initial;
		margin-bottom: 3rem;
	}
}

.bottom-catalog-wrapper {
    background-image: url('../images/bottom_catalogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 400px;
    height: 400px;
}

.bottom-catalog-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    > h2 {
        text-align: center;
        @include mq(mobile, tablet) {
            font-size: 2rem;
            width: 90%;
            margin: 0 auto 1rem;
        }
        @include mq(tablet, desktop) {
            font-size: 3rem;
            width: 90%;
            margin: 0 auto 1rem;
        }
        @include mq(desktop, wide) {
            font-size: 3rem;
            width: 50%;
            margin: 0 auto 1rem;
        }
        @include mq(wide) {
            font-size: 3rem;
            width: 50%;
            margin: 0 auto 1rem;
        }
    }
}

.show-more-bottom-catalog {
    background: $white;
    padding: 10px 30px;
    color: $orange;
    font-weight: 500;
    text-align: center;
    font-size: 15px;
    border-radius: 3px;
    text-decoration: none;
    &:focus, &:hover, &:active {
        color: $orange;
        text-decoration: underline;
    }
}

.title-with-border {
    display: inline-block;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
