.featuredNewsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 4rem;
	gap: 1rem;
	-webkit-box-shadow: 15px 25px 32px -17px rgba(204,204,204,1);
	-moz-box-shadow: 15px 25px 32px -17px rgba(204,204,204,1);
	box-shadow: 15px 25px 32px -17px rgba(204,204,204,1);

	@include mq(mobile, tablet) {
		flex-direction: column;
		box-shadow: none;
	}

	> .featuredNewsText {
		flex: 1 1;
	}
}

.featuredNewsText {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	> a {
		text-decoration: none;
		> h3 {
			margin-top: 2rem;
			font-size: 1.5rem;
			color: #333;
			font-weight: 700;
			@include mq(mobile, desktop) {
				margin-top: 0;
			}
		}
	}
	> p {
		font-weight: 400;
		font-size: 1rem;
		color: #666;
	}

	> span {
		font-weight: 500;
		font-size: 0.85rem;
		color: #797979;
		margin-top: 2rem;
		@include mq(mobile, desktop) {
			margin-top: 0;
		}
	}
	> .showUnderlineButton {
		color: $orange;
		font-weight: 500;
		font-size: 1rem;
		text-decoration: underline;
		margin-top: 3rem;

		@include mq(mobile, desktop) {
			margin-top: 1rem;
			text-align: left;
		}
		&:hover, &:focus, &:active {
			color: $orange;
			text-decoration: none;
		}
	}
}

.newsGridWrapper {
	display: grid;
	gap: 2rem 1rem;
	margin-bottom: 10rem;
	grid-template-columns: 1fr 1fr 1fr;
	@include mq(mobile, tablet) {
		grid-template-columns: 1fr;
	}
	@include mq(tablet, desktop) {
		grid-template-columns: 1fr 1fr;
	}
}

.newsBlock {
	border: 1px solid $grey;
	border-radius: 0.4375rem;
	padding: 1.5rem 2rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	> a {
		text-decoration: none;
		> h3 {
			font-size: 1.125rem;
			color: #333;
			font-weight: 700;
		}
	}
	> p {
		font-weight: 400;
		font-size: 1rem;
		color: $grey;
	}
}
.newsBlockButton {
	text-decoration: none;
	color: $orange;
	font-weight: 500;
	font-size: 1rem;
	align-self: flex-end;
	&:hover, &:focus, &:active {
		text-decoration: underline;
		color: $orange;
	}
	> svg {
		margin-left: 3px;
	}
}

.news-links {
	flex: 0 1 40%;
	@include mq(tablet, desktop) {
		flex: 0 1 45%;
	}
	@include mq(desktop, wide) {
		flex: 0 1 45%;
	}
	> img {
		display: block;
		width: 100%;
	}
}

.featured-images {
	@include mq(mobile, tablet) {
		width: 100%;
	}
}

.news-grid-container {
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;

	&:hover > a > img {
		transform: scale(1.1);
        border-radius: 10px;
	}

	&:hover > a > h3 {
		color: darken($grey, 25%);
	}

	&:hover > p {
		color: darken($grey, 25%);
	}

	> a > img {
		width: 100%;
		display: block;
		margin: 0 auto;
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
	}

	> a {
		text-decoration: none;
	}


	> a > h3 {
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;

		color: $grey;
		font-size: 1.2rem;
		font-weight: 600;
		margin-top: 1rem;
        text-transform: uppercase;
	}

	> p {
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-ms-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
		font-size: 15px;
		font-weight: 400;
		color: $grey;
	}

	border: 1px solid #e7eef1;
	margin-left: -1px;
	padding: 13px;
	@include mq($from: desktop) {
		flex: 0 1 25%;
	}
}


.news-grid-wrapper {
	display: grid;
	grid-gap: 1.5rem;

	@include mq(mobile, tablet) {
		grid-template-columns: 1fr;
	}
	@include mq(tablet, desktop) {
		grid-template-columns: 0.6fr 0.6fr;
	}
	@include mq(desktop, wide) {
		grid-template-columns: 0.6fr 0.6fr 0.6fr;
	}
	@include mq(wide) {
		grid-template-columns: 0.6fr 0.6fr 0.6fr;
	}
}

.link-img-news {
	overflow: hidden;
	max-height: 250px;
}

.image-grid-news {
    object-fit: cover;
    object-position: center;

    @include mq(mobile, tablet) {
        height: inherit;
    }
    @include mq($from: tablet) {
        height: 10rem;
    }
}

.share-date-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    @include mq(mobile, tablet) {
        grid-template-columns: 1fr;
        justify-content: flex-start;
    }
}

.innerNewsDateText {
    margin-bottom: 0;
	font-size: 0.875rem;
	color: #666;
    text-align: right;
    @include mq(mobile, tablet) {
        text-align: left;
    }
}

.inner-news-body-container {
    @include mq(mobile, tablet) {
        width: 100%;
    }

    img {
        @include mq(mobile, tablet) {
            display: block;
            width: 100%;
            margin: 0.5rem 0;
        }
    }
}
