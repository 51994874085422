.middle-title-matebar {
	@include mq(mobile, tablet) {
	}
	@include mq(tablet, desktop) {
		width: 70%;
	}
	@include mq($from: desktop) {
		width: 70%;
	}
}

.reserva-icons-wrapper {
	@include mq(mobile, tablet) {
		display: block;
	}

	@include mq(tablet, desktop) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}

	@include mq(desktop, wide) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}

	@include mq(wide) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}

	> div {
		border: 1px solid #c5c5c5;
		border-radius: 8px;
		padding: 10px;
		@include mq(mobile, tablet) {
			margin-bottom: 20px;
		}
		> img {
			margin: 0 auto;
			display: block;
		}
		> p {
			@include mq(mobile, tablet) {
				font-size: 1.2rem;
			}
		}
	}
}

.aventura-text {
	color: $grey;
	@include mq(mobile, tablet) {
		font-size: 1.2rem;
	}
	@include mq(tablet, desktop) {
		font-size: 1.4rem;
	}
	@include mq(desktop, wide) {
		font-size: 1.6rem;
	}
	@include mq(wide) {
		font-size: 1.8rem;
	}
}

.calendar-icon {
	margin-left: auto;
	@include mq(mobile, tablet) {
		margin-left: auto;
		margin-right: auto;
		width: 150px;
	}
	@include mq(tablet, desktop) {
		width: 115px;
	}
	@include mq(desktop, wide) {
		width: 115px;
	}
}

.reserva-block {
	@include mq(tablet, desktop) {
		display: block !important;
	}
	@include mq(desktop, wide) {
		align-items: center;
	}
}

.reserva-image {
	@include mq(tablet, desktop) {
		margin-top: 2rem;
	}
}

.aventura-block-wrapper {
	margin-top: 2rem;
	@include mq(mobile, tablet) {
		display: block;
	}

	@include mq(tablet, desktop) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
	@include mq(desktop, wide) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 1rem;
	}
	@include mq(wide) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 1rem;
	}

	> div {
		border: 1px solid #c5c5c5;
		border-radius: 8px;
		padding: 10px;
		@include mq(mobile, tablet) {
			margin-bottom: 20px;
		}

		> img {
			display: block;
			margin: 0 auto;
			width: 150px;
		}
		> p {
			color: $grey;
			font-weight: 600;
			@include mq(mobile, tablet) {
				font-size: 1.2rem;
			}
			@include mq(tablet, desktop) {
				font-size: 1.1rem;
			}
			@include mq(desktop, wide) {
				font-size: 1.2rem;
			}
			@include mq(wide) {
				font-size: 1.2rem;
			}
		}
	}
}

.image-logo-reserva {
	width: 70%;
	display: block;
	margin: 0 auto 2.5rem;
    @include mq(mobile, tablet) {
        margin: 0 auto 1rem;
    }
	@include mq(desktop, wide) {
		width: 85%;
	}
}

.image-logo-aventura {
	width: 400px;
	display: block;
	margin: 2rem auto 2.5rem;
	@include mq(mobile, tablet) {
		width: 90%;
	}
}

.banner-text-wrapper {
    width: 100%;
    height: 535px;
    position: relative;
    > div {
        @include mq(mobile, tablet) {
            width: 90%;
            font-size: 28px;
        }
        @include mq(tablet, desktop) {
            width: 60%;
            font-size: 28px;
        }
        @include mq(desktop, wide) {
            width: 45%;
            font-size: 28px;
        }
        @include mq(wide) {
            width: 45%;
            font-size: 28px;
        }
    }
    @include mq(tablet, desktop) {
        height: 360px;
    }
}

.bg-turismo-text {
    background-image: url('../images/mate_bar_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(mobile, tablet) {
        background-attachment: initial;
        background-position: center;
    }
    @include mq(tablet, desktop) {
        background-attachment: initial;
        background-position: center;
    }
}

.top-bg-design {
    width: 100%;
    position: absolute;
    top: -1px;
    left: 0;
    display: block;
}

.bottom-bg-design {
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    display: block;
}

.turismo-yerbatero-title {
    color: $green;
    @include mq(mobile, tablet) {
        font-size: 40px;
    }
    @include mq(tablet, desktop) {
        font-size: 45px;
    }
    @include mq(desktop, wide) {
        font-size: 56px;
    }
    @include mq(wide) {
        font-size: 56px;
    }
}

.turismo-yerbatero-calendar {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    grid-gap: 1rem;
    > img {
        width: 60px;
    }
}
